/* You can add global styles to this file, and also import other style files */

@import '~bootstrap/dist/css/bootstrap.min.css';
@import "~@ng-select/ng-select/themes/default.theme.css";


.container{
  max-width: 1225px;
}
.content{
  background-color: white;
}

h1{
  color: #04A3E9;
}
h2{
  color: #04A3E9;
}
h5{
  color: #04A3E9;
}
.title{
  text-align: center;
  padding: 1em;
}

.page-item.active .page-link{
  background-color: #f47b1e;
}

.cc-window{
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: #ffffff;
}

.btn-primary.disabled, .btn-primary:disabled{
  background-color: #fdbc50;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

